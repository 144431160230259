import Breadcrumb from '../../../../../components/breadcrumb'
import BasePreMatricula from '../../basePreMatricula'
import { useState } from 'react'
import { pesquisaNome } from '../../../../../services/processos/preMatricula'
import Table from '../../../../../components/table'
import { useMemo } from 'react'


const PreMatriculaPesquisarNome = () => {

    const [nome, setNome] = useState('')
    const [data, setData] = useState([])

    async function handleSearch(){
        const response = await pesquisaNome(nome)
        setData(response)
    }

    function abrirInscricao(cpf){
        // localStorage.setItem('CPF', cpf)
        window.open('https://web.educacaoararuama.rj.gov.br/formulario/cadastro-reserva/inscricao/' + cpf, '_blank')
    
      }

    const columns = useMemo(
        () => [
          { Header: '#', accessor: 'id' },
          { Header: 'Unidade', accessor: 'unidade' },
          { Header: 'Opção', accessor: 'opcao' },
          { Header: 'Estudante', accessor: 'nome' },
          // { Header: 'CPF', accessor: 'cpf' },
          {
            Header: 'Status', accessor: 'status',
            Cell: ({ row }) => (
              <span className={`badge bg-dark`}>
                {row.original.status}
              </span>
            )
          },
          {
            Header: '',
            accessor: 'actions',
            Cell: ({ row }) => (
              <>
    
    <button
                  onClick={() =>
                    {  abrirInscricao(row.original.id)}
                    // navigate(urls.processosSeletivosVInscricao + row.original.id)
                  }
                  className="btn btn-sm btn-primary"
                >
                  <i className="material-icons-two-tone">edit_note</i>
                </button>
                
               
                  
               
                
              </>
            )
          },
        ])
   
    



return (
    <BasePreMatricula>
       <Breadcrumb title={'PESQUISAR NOME'} itens={['PRÉ-MATRICULA 2024', 'INSCRIÇÕES', 'PESQUISAR']} />
       {/* <form id="form" onSubmit={onsubmit()}> */}
      <div className="card-body">
      {/* <div className='row'>
                    <div className='col-8'>
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input type="text" className="form-control" id="nome"
                      />
                      
                    </div>
                    <div className='col-4'>
                        <button></button>
                    </div>
                  </div> */}

<div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          marginTop: 10,
          marginBottom: 20
        }}
      >

        <div className="form-group" style={{ width: '100%', marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
        </div>

      
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
        </div>

        <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data?.data ? data?.data : []}
              hiddenColluns={['cpf']}
            //   hasPagination={true}
            //   skip={0}
            //   limit={20}
            //   totalItems={data?.data.length}
            />
          </div>
        </div>
      </div>


        </div>
        {/* </form> */}
       
      </BasePreMatricula>
)
}

export default PreMatriculaPesquisarNome