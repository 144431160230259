import Breadcrumb from "../../../../components/breadcrumb";
import BaseBilingue from "./baseBilingue";
import { getDashboardProcessoSeletivo } from "../../../../services/dashboard";
import { useQuery } from 'react-query';
import Spinner from "../../../../components/spinner";

const HomeBilingue = () => {
  const { data, isLoading } = useQuery('getDashboardProcessoSeletivo', getDashboardProcessoSeletivo, {
    retry: 3
  });

  return (
    <BaseBilingue>
      <Breadcrumb title={'Informações Gerais'} itens={['Processo Seletivo', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
          <h4>Dados Gerais Inscrições</h4>
            <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições</span>
                        <span className="widget-stats-amount">{data.totalInscricoesGeral}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições / CPF</span>
                        <span className="widget-stats-amount">{data.totalInscricoes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>


            <div className="row">
            <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Em Análise</span>
                        <span className="widget-stats-amount">{data.totalInscricoesEmAnalise}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Cancelado</span>
                        <span className="widget-stats-amount">{data.totalInscricoesCanceladas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Deferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesDeferidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Indeferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesIndeferidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>


            {/* <h4 style={{ marginBottom: 20, marginTop: 20 }}>Total Inscrições Agronegócio & Turismo</h4> */}
            {/* <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições</span>
                        <span className="widget-stats-amount">{data.totalInscricoesGeralTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições / CPF</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div> */}


            {/* <div className="row">
            <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Em Análise</span>
                        <span className="widget-stats-amount">{data.totalInscricoesEmAnaliseTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Cancelado</span>
                        <span className="widget-stats-amount">{data.totalInscricoesCanceladasTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Deferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesDeferidasTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Indeferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesIndeferidasTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div> */}
            

            {/* <h4 style={{ marginBottom: 20, marginTop: 20 }}>Total Inscrições Getúlio Vargas (Gastronomia / Hotelaria)</h4> */}
            {/* <div className="row">
            <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições</span>
                        <span className="widget-stats-amount">{data.totalInscricoesGeralTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições / CPF</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div> */}


            {/* <div className="row">
            <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Em Análise</span>
                        <span className="widget-stats-amount">{data.totalInscricoesEmAnaliseTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Cancelado</span>
                        <span className="widget-stats-amount">{data.totalInscricoesCanceladasTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Deferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesDeferidasTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Indeferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesIndeferidasTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div> */}
            
          </>
        )}
    </BaseBilingue>
  )
};

export default HomeBilingue;

