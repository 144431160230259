import Breadcrumb from '../../../../../../components/breadcrumb'
import BasePreMatricula from '../../../basePreMatricula';
import Alert from '@mui/material/Alert';
import Table from '../../../../../../components/table';
import { useMemo, useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import { getEscolaridades } from '../../../../../../services/escolaridades';
import { getInscricoesRelatorioNominais } from '../../../../../../services/processos/preMatricula';
import handleDownloadExcel from '../../../../../../utils/handleDownloadExcel';
import { toast } from 'react-toastify';
import FormInput from '../../../../../../components/formInput';
import { useForm } from 'react-hook-form';


const RelatorioConvocadosPorPeriodoNominal = () => {

  const [escolaridade, setEscolaridade] = useState(0)
  const [skip, setSkip] = useState(0);
  const [status, setStatus] = useState(2);
  const [exportar, setExportar] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);
  const nomeArquivo = 'relatotio-pre-matricula-2024-convocados-por-periodo-nominal.xlsx'



  const { register, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      dataInicial: "",
      dataFinal: ""
    }
  })

  function abrirInscricao(cpf){
    localStorage.setItem('CPF', cpf)
    window.open('https://web.educacaoararuama.rj.gov.br/formulario/cadastro-reserva/inscricao/' + cpf, '_blank')

  }

async function fetchInscricoes(){

    const dataInicial = watch('dataInicial')
    const dataFinal = watch('dataFinal')

    if (dataInicial !== '' && dataFinal !== ''){

    if(dataInicial > dataFinal){
        return toast.warning('Data inicial não pode ser maior que a data final.')
    }

    if(dataFinal < dataInicial){
        return toast.warning('Data final não pode ser menor que a data inicial.')
    }
}

    if (dataInicial != '' && dataFinal  != ''){
        var response = await getInscricoesRelatorioNominais(dataInicial, dataFinal, skip, limit, exportar, status)
        setData(response || []) 
    }
    
}

const exportarDados = async () => {

    const dataInicial = watch('dataInicial')
    const dataFinal = watch('dataFinal')

    if (dataInicial !== '' && dataFinal !== ''){

    if(dataInicial > dataFinal){
        return toast.warning('Data inicial não pode ser maior que a data final.')
    }

    if(dataFinal < dataInicial){
        return toast.warning('Data final não pode ser menor que a data inicial.')
    }
}
    
  if(data.length === 0 || data?.total === 0){
    return toast.warning('Não há dados para exportar.')
  }

  try {

  const relatorioData = await getInscricoesRelatorioNominais(dataInicial, dataFinal, skip, limit, 1, status)

  if (relatorioData) {
    return handleDownloadExcel(relatorioData, nomeArquivo);
  } else {
      return toast.error('Falha ao obter o relatório');
      // Tratar falha na obtenção do relatório
  }
  } catch (error) {
    return toast.error('Erro ao buscar relatório');
    // Tratar erro na chamada da função
  }
 
  // return await handleDownloadExcel(relatorioData, titulo);
}

useEffect(() => {
  fetchInscricoes()
}, [watch('dataInicial'), watch('dataFinal'), limit, skip])


  const columns = useMemo(
      () => [
    { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Unidade', accessor: 'unidadeNome' },
      { Header: 'Escolaridade', accessor: 'escolaridadeDescricao' },
      { Header: 'Turno', accessor: 'turnoDescricao' },
      {
        Header: 'Data Convocação', accessor: 'dataConvocacao',
        Cell: ({ row }) => (
          row.original.dataConvocacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.dataConvocacao.split('T')[1].split('.')[0]
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>

            <button
              onClick={() =>
                {  abrirInscricao(row.original.id)}
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
            
              
              
           
            
          </>
        )
      },
      ],
      []
  )
    


return (
    <BasePreMatricula>
      <Breadcrumb
        title={'POR PERÍODO - NOMINAL'}
        itens={['PRÉ MATRÍCULA 2024', 'RELATÓRIOS', 'CONVOCADOS', 'POR PERÍODO - NOMINAL']}
        hasExcelButton 
        onClickDownload={() => exportarDados()} 
      />

{/* <Alert variant="filled" severity="info" style={{marginBottom: 20}}>Resultado com base nas inscrições como primeira opção.</Alert> */}
<div className="row" style={{ marginBottom: 20 }}>
<div className="col-md-6">
                    <FormInput
                    type='date'
                    atribute="dataInicial"
                    label="Data Inicial"
                    // defaultValue={dataInicial}
                    // onChange={handleDataInicialChange}
                    register={register}
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                    type='date'
                    atribute="dataFinal"
                    label="Data Final"
                    // defaultValue={dataInicial}
                    // onChange={handleDataFinalChange}
                    register={register}
                    />
                   
                </div>
        </div>

        <div className="row">
        <div className="col-12">
          <div className="card">
        <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
            </div>
            </div>
            </div>

      </BasePreMatricula>

      
)
}

export default RelatorioConvocadosPorPeriodoNominal