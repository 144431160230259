import api from '../..'

async function getInscricoesPreMatricula(unidadeId, escolaridadeId, turnoId, transferencia, deficiente, status, nome, skip, limit) {
  
  return await api
    .get(`prematricula?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&estudantedarede=${transferencia}&deficiente=${deficiente}&status=${status}&nome=${nome}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getInscricoesMatricula(unidadeId, nome, skip, limit) {
  
  return await api
    .get(`prematricula/matriculas?unidadeId=${unidadeId}&nome=${nome}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getInscricoesRelatorio(unidadeId = 0, escolaridadeId = 0, turnoId= 0, alunoRede= 0, deficiente = 0, bairro = '', skip = 0, limit = 20, exportar = 0, status = 1) {
  
  let config = {};

  // Se exportar for 1, ajusta a configuração para resposta do tipo 'arraybuffer'
  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`prematricula/relatorios?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&alunoRede=${alunoRede}&deficiente=${deficiente}&bairro=${bairro}&skip=${skip}&limit=${limit}&exportar=${exportar}&status=${status}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getInscricoesRelatorioNominais(dataInicial, dataFinal, skip = 0, limit = 20, exportar = 0, status = 1) {
  
  let config = {};

  // Se exportar for 1, ajusta a configuração para resposta do tipo 'arraybuffer'
  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`prematricula/relatorios/nominais?dataInicial=${dataInicial}&dataFinal=${dataFinal}&skip=${skip}&limit=${limit}&exportar=${exportar}&status=${status}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getInscricoesRelatorioQuantitativos(dataInicial, dataFinal, skip = 0, limit = 20, exportar = 0, status = 1) {
  
  let config = {};

  // Se exportar for 1, ajusta a configuração para resposta do tipo 'arraybuffer'
  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`prematricula/relatorios/quantitativo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&skip=${skip}&limit=${limit}&exportar=${exportar}&status=${status}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getBairros() {
  
  return await api
    .get(`prematricula/bairros`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function postPreMatricula(data) {
  return await api
    .post('prematricula', data)
    .then(async (res) => {
      return res;
    });
}

async function postStatusPreMatricula(data) {
    return await api
      .post('prematricula/status', data)
      .then(async (res) => {
        return res;
      });
  }

  async function postIndeferirPreMatricula(data) {
    return await api
      .post('prematricula/indeferir', data)
      .then(async (res) => {
        return res;
      });
  }

  async function postEnturmar(data) {
    return await api
      .post('prematricula/enturmar', data)
      .then(async (res) => {
        return res;
      });
  }

  async function getHistorico(id, opcao) {
  
    return await api
      .get(`prematricula/historico?id=${id}&opcao=${opcao}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function pesquisaNome(nome) {
  
    return await api
      .get(`prematricula/pesquisa?nome=${nome}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };


export {
  getInscricoesPreMatricula,
  postPreMatricula,
  postStatusPreMatricula,
  getHistorico,
  postEnturmar,
  postIndeferirPreMatricula,
  pesquisaNome,
  getBairros,
  getInscricoesRelatorio,
  getInscricoesMatricula,
  getInscricoesRelatorioNominais,
  getInscricoesRelatorioQuantitativos
}