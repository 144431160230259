import urlsServidores from "./urlsServidores";

const linksServidor = {

  processo_seletivo: {
    id: 'processo_seletivo',
    text: 'Processo Seletivo',
    type: 'title',
    visible: true,
    itens: {
      dashboard: {
        id: 'dashboard',
        text: 'Dashboard',
        path: urlsServidores.dashboard,
        icon: 'dashboard',
        visible: true,
      },
      inscricoes: {
        id: 'inscricoes',
        text: 'Inscrições',
        path: urlsServidores.inscricoes,
        icon: 'groups',
        visible: true,
      },
    }
  },
};

export default linksServidor;