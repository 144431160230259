import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Notifications from '../notificacoes'
import api from '../../services/index'
import { Link } from 'react-router-dom';

const Logo = (perfil = true) => {
  const isMenuOpen = useSelector(state => state.panel.isMenuOpen);
  
  // const { user } = JSON.parse(localStorage.getItem('persist:root'));
  // const { user: userData } = JSON.parse(user);

  const [user, setUser] = useState();
  const [userData, setUserData] = useState(); 

  const modulosLink = perfil === true ? '/modulos' : '#';

  if (perfil === true){
    setUser(JSON.parse(localStorage.getItem('persist:root')));
    setUserData(JSON.parse(user.user));
  }

  const [notificationData, setNotificationData] = useState();

  useEffect(() => {

    async function fetchData() {
      const notifications = await api.get('/shared/notificacao/perfil');
      setNotificationData(notifications.data)
    }
    if (perfil === true){
      fetchData()
    }
    
  }, [])

  const [shouldOpenNotificationOptions, setShouldOpenNotificationOptions] = useState(false);

  const backgroundStyle = {
    backgroundImage: `url(/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png)`,
    backgroundRepeat: 'no-repeat',
    // Add more styling here as needed, such as backgroundSize, backgroundPosition, etc.
  };

  return (
    <div className={isMenuOpen ? "logo canvas-sidebar-hidden-logo" : "logo"} style={{ overflow: 'inherit' }}>
      <a href={modulosLink} className="logo-icon" style={backgroundStyle}>
        <span className="logo-text">{process.env.REACT_APP_SECRETARIA_CIDADE}</span>
      </a>
      {perfil === true && (
        <div className="sidebar-user-switcher" style={{ position: 'relative' }}>
        <Link to="/gestao/perfil" title="Perfil">
          <img src="/assets/images/avatars/avatar-male.svg" alt="avatar perfil" />
          <span className="activity-indicator" />
          <span className="user-info-text">
            {userData?.usuarioLogin.split('.')[0] || 'Usuário'}
            <br />
            <span className="user-state-info">{userData?.usuario}</span>
          </span>
        </Link>
        <div
          onClick={() => setShouldOpenNotificationOptions(!shouldOpenNotificationOptions)}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20px',
            height: '20px',
            backgroundColor: '#FF9500',
            borderRadius: '50%',
            cursor: 'pointer',
            top: '-3px',
            right: '-9px'
          }}
        >
          <span style={{ fontSize: '12px', color: '#FFF' }}>{notificationData?.total}</span>
        </div>
        {shouldOpenNotificationOptions && <Notifications data={notificationData?.data} userId={userData?.id} />}
      </div>
      )}
      
    </div>
  )
};

export default Logo;