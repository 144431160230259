import Breadcrumb from "../../../components/breadcrumb";
import BasePreMatricula from "./basePreMatricula";
import { getDashboardPreMatricula } from "../../../services/dashboard";
import { useQuery } from 'react-query';
import Spinner from '../../../components/spinner';

const HomePreMatricula = () => {
  const { data, isLoading } = useQuery('getDashboardPreMatricula', getDashboardPreMatricula, {
    retry: 3
  });

  return (
    <BasePreMatricula>
      <Breadcrumb title={'Informações Gerais'} itens={['Pré-Matricula 2024', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
          <h4>Dados Gerais Inscrições</h4>

          <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL INSCRITOS</span>
                        <span className="widget-stats-amount">{data?.inscricoesTotal}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL INSCRITOS POR CPF</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL INSCRITOS PRÉ-MÁTRÍCULA</span>
                        <span className="widget-stats-amount">{data?.inscricoesListaEsperaTotal}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL INSCRITOS PRÉ-MATRÍCULA (REDE)</span>
                        <span className="widget-stats-amount">{data?.inscricoesListaEsperaTotalRede}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL INSCRITOS PRÉ-MATRÍCULA (Ñ REDE)</span>
                        <span className="widget-stats-amount">{data?.inscricoesListaEsperaTotalForaRede}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL CONVOCADO</span>
                        <span className="widget-stats-amount">{data?.totalConvocados}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">TOTAL MATRICULADOS</span>
                        <span className="widget-stats-amount">{data?.totalMatriculados}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

          <h4>Dados Por Escolaridade (Pré-Matrícula)</h4>

          <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">1º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas1}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">2º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">3º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas3}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">4º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas4}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">5º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas5}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">6º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas6}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">7º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas8}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">8º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas10}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">9º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas11}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE I</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas12}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE II</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas13}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE III</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas14}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE IV</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas15}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE II</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas16}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE III</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas17}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE IV</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas18}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE IX</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas19}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE V</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas20}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE VI</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas21}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE VII</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas22}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">FASE VIII</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas23}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">PRÉ I</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas24}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">PRÉ II</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas25}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>
           
            
          </>
        )}
    </BasePreMatricula>
  )
};

export default HomePreMatricula;

