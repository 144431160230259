import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../../../components/breadcrumb'
import Table from '../../../../../components/table'
import BaseServidores from '../../public/baseServidores'
import urlsServidores from '../../public/urlsServidores'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

import { listarInscricoesServidores, listarMunicipios, listarPolos, gerarRelatorioInscricoes, indeferirInscricao, listaAnexo, downloadAnexo, gerarRelatorioInscricoesServidores} from '../../../../../services/processos/remanejamento'
import { useQuery, useMutation } from 'react-query';

const ServidoresIncricoes = () => {
  const navigate = useNavigate()
  // const [search, setSearch] = useState('')

  let cargoFiltro = localStorage.getItem('psServidorCargoFiltro') === null ? 0 : localStorage.getItem('psServidorCargoFiltro');
  let statusFiltro =  localStorage.getItem('psServidorStatusFiltro') === null ? 1 :  localStorage.getItem('psServidorStatusFiltro');
  let deficienteFiltro = localStorage.getItem('psServidorDeficienteFiltro') === null ? 0 :  localStorage.getItem('psServidorDeficienteFiltro');
  let recursoFiltro = localStorage.getItem('psServidorRecursoFiltro') === null ? 0 :  localStorage.getItem('psServidorRecursoFiltro');
  let cpfFiltro = localStorage.getItem('psServidorCpfFiltro') === null ? '' :  localStorage.getItem('psServidorCpfFiltro');
  let nomeFiltro = localStorage.getItem('psServidorNomeFiltro') === null ? '' :  localStorage.getItem('psServidorNomeFiltro');


  const [pesquisaNome, setPesquisaNome] = useState(nomeFiltro)
  const [pesquisaCPF, setpesquisaCPF] = useState(cpfFiltro)
  const [data, setData] = useState([])

  const [cargo, setCargo] = useState(cargoFiltro)
  const [status, setStatus] = useState(statusFiltro)
  const [deficiente, setDeficiente] = useState(deficienteFiltro)
  const [recurso, setRecurso] = useState(recursoFiltro)
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);




  const { mutate } = useMutation(indeferirInscricao, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { isError, isLoading, refetch } = useQuery(
    'inscricoes',
    () => listarInscricoesServidores(cargo, status, pesquisaCPF, pesquisaNome, skip,limit, deficiente, recurso),
    {
      retry: 0,
      enabled: false,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )

  async function abrirAnexo(id){
    // await localStorage.setItem('servidorProcessoSeletivo', JSON.stringify(data))
    navigate(urlsServidores.anexo + '/' + id)
  }


  const handleAnexoDownload = async (id, nome, anexoId) => {
    let anexo = await downloadAnexo(id, nome, anexoId)
  }

  // Filtros abaixo


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'Nascimento', accessor: 'dataNascimento',
        Cell: ({ row }) => (
          row.original.dataNascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      // {
      //   Header: 'Escola', accessor: 'unidadeId',
      //   Cell: ({ row }) => (
      //     findMunicipio(row.original.unidadeId)
      //   )
      // },
      { Header: 'Cidade', accessor: 'cidade' },
      { Header: 'Pontuação', accessor: 'pontuacao' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.status > 0 && (
              <button onClick={() => navigate(urlsServidores.indeferir + '/' + row.original.id, {
                state: {
                  id: row.original.id,
                }
              })} className='btn btn-sm btn-warning'>
                <i className="material-icons-two-tone">edit_note</i> atualizar
              </button>
              
            )}
            {/* {row.original.anexoCertificados1 && (
              <button
              onClick={() =>
                handleAnexoDownload(row.original.id, row.original.nome, row.original.anexoCertificados1)
              }
              className="btn btn-sm btn-secondary"
            >
              <i className="material-icons-two-tone">edit_note</i> certificado
            </button>
              
            )} */}
            <button
              onClick={() =>
                {  window.open(urlsServidores.visualizar + row.original.id, '_blank')}
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
            <button onClick={() => abrirAnexo(row.original.id)} className='btn btn-sm btn-success'>
                <i className="material-icons-two-tone">edit_note</i> anexos
              </button>
          </>
        )
      }
    ],
    [navigate, mutate]
  )

  useEffect(() => {
    refetch()
    // refetchEscolaridades()
  }, [refetch, limit, skip])

  const handleSearch = () => {
    refetch()
  }

  const handleDownloadExcel = async () => {
    await gerarRelatorioInscricoesServidores(cargo, status, pesquisaCPF, pesquisaNome, skip,limit, deficiente, recurso)
  }



  return (
    <BaseServidores>
      <Breadcrumb
        title={'Inscrições'}
        itens={['Processo Seletivo', 'Inscrições', 'Lista Geral']}
        hasExcelButton 
        onClickDownload={() => handleDownloadExcel()}
        // actions={{
        //   link: urls.processoSeletivoInscricoesCadastrar,
        //   text: 'Nova Inscição'
        // }}
      />

      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="cargoId" className="form-label">
            Cargo
            </label>
            <select
              className="form-select"
              id="cargoId"
              // value={unidadeId}
              onChange={async e => {
                await setCargo(e.target.value)
                await localStorage.setItem('psServidorCargoFiltro', e.target.value);
              }}
            >
              
                <>
                <option value={0}>TODOS</option>
                <option value={1}>AGENTE DE SERVIÇOS GERAIS</option>
                <option value={2}>AUXILIAR DE DISCIPLINA</option>
                <option value={3}>MERENDEIRA</option>
                <option value={4}>MONITOR DE TRANSPORTE ESCOLAR</option>
                <option value={5}>MOTORISTA D</option>
                <option value={6}>NUTRICIONISTA</option>
                <option value={7}>OFICIAL ADMINISTRATIVO</option>
                <option value={8}>ORIENTADOR EDUCACIONAL</option>
                <option value={9}>ORIENTADOR PEDAGÓGICO</option>
                <option value={10}>PROFESSOR I</option>
                <option value={11}>PROFESSOR II</option>
                <option value={12}>SECRETÁRIO ESCOLAR</option>
                <option value={13}>VIGIA</option>
                </>
              
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="status" className="form-label">
              Status?
            </label>
            <select
              className="form-select"
              id="status"
              value={status}
              onChange={async e => {
                await setStatus(e.target.value)
                await localStorage.setItem('psServidorStatusFiltro', e.target.value);
              }}
            >
                <>
                <option value={0}>TODOS</option>
                  <option value={1}>AGUARDANDO</option>
                  <option value={2}>HOMOLOGADO</option>
                  <option value={3}>CONVOCADO</option>
                  <option value={4}>NÃO HOMOLOGADO</option>
                  <option value={5}>NÃO COMPARECEU</option>
                  <option value={6}>RECURSO ACEITO</option>
                  <option value={7}>RECURSO NEGADO</option>
                  <option value={8}>CANCELADO</option>
                </>
            </select>
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="status" className="form-label">
              Deficiente?
            </label>
            <select
              className="form-select"
              id="deficiente"
              value={deficiente}
              onChange={async e => {
                await setDeficiente(e.target.value)
                await localStorage.setItem('psServidorDeficienteFiltro', e.target.value);
              }}
            >
                <>
                  <option value={0}>NÃO</option>
                  <option value={1}>SIM</option>
                </>
            </select>
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="recurso" className="form-label">
              Recurso?
            </label>
            <select
              className="form-select"
              id="recurso"
              value={recurso}
              onChange={async e => {
                await setRecurso(e.target.value)
                await localStorage.setItem('psServidorRecursoFiltro', e.target.value);
              }}
            >
                <>
                  <option value={0}>NÃO</option>
                  <option value={1}>SIM</option>
                </>
            </select>
          </div>
        </div>

      
        
        

        

        

        

      </div>

      


      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >

        <div className="form-group" style={{ width: '100%', maxWidth: 320, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            
            value={pesquisaCPF}
            onChange={async e => {
              await setpesquisaCPF(e.target.value)
              await localStorage.setItem('psServidorCpfFiltro', e.target.value);
            }}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 520, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Servidor"
            value={pesquisaNome}
            onChange={async e => {
              await setPesquisaNome(e.target.value)
              await localStorage.setItem('psServidorNomeFiltro', e.target.value);
            }}
          />
        </div>

        {/* <div className="form-group" style={{ width: '100%', maxWidth: 395 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome Reponsável"
            value={pesquisarResponsavel}
            onChange={e => setPesquisarResponsavel(e.target.value)}
          />
        </div> */}
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
      </div>



      
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={["id"]}
            />
          </div>
        </div>
      </div>
    </BaseServidores>
  )
}

export default ServidoresIncricoes
