import Breadcrumb from '../../components/breadcrumb';
import BaseBiblioteca from "./baseBiblioteca";
import Spinner from '../../components/spinner';
import { useEffect, useState } from "react";
import api from '../../services'

const HomeBiblioteca = () => {
  const unidadeID = localStorage.getItem('unidadeID');
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   async function fetchData() {
  //     setIsLoading(true)
  //     const response = await api.get(`/estatistica/biblioteca`);
  //     setData(response.data.data)
  //     setIsLoading(false)
  //   }
  //   fetchData()
  // }, [])

  return (
    <BaseBiblioteca>
      <Breadcrumb title={'Informações Gerais'} itens={['Biblioteca', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outlined">books</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Livros</span>
                        <span className="widget-stats-amount">{data?.livros || 2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </>
        )}
    </BaseBiblioteca>
  )
};

export default HomeBiblioteca;