import Breadcrumb from "../../../../components/breadcrumb";
import BaseRemanejamento from "./baseRemanejamento";
import { getDashboardRemanejamento } from "../../../../services/dashboard";
import { useQuery } from 'react-query';
import Spinner from "../../../../components/spinner";

const HomeRemanejamento = () => {
  const { data, isLoading } = useQuery('getDashboardRemanejamento', getDashboardRemanejamento, {
    retry: 3
  });

  return (
    <BaseRemanejamento>
      <Breadcrumb title={'Informações Gerais'} itens={['Remanejamento', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
          <h4 style={{ marginBottom: 20 }}>Dados Gerais Inscrições</h4>
            <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições</span>
                        <span className="widget-stats-amount">{data.totalInscricoesEmAnalise + data.totalInscricoesDeferidas + data.totalInscricoesIndeferidas + data.totalInscricoesCanceladas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscrições Válidas</span>
                        <span className="widget-stats-amount">{data.totalInscricoesEmAnalise + data.totalInscricoesDeferidas + data.totalInscricoesIndeferidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>


            <div className="row">
            <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Em Análise</span>
                        <span className="widget-stats-amount">{data.totalInscricoesEmAnalise}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Cancelado</span>
                        <span className="widget-stats-amount">{data.totalInscricoesCanceladas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Deferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesDeferidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Indeferido</span>
                        <span className="widget-stats-amount">{data.totalInscricoesIndeferidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>


            <h4 style={{ marginBottom: 20, marginTop: 20 }}>Total Inscrições X Escolaridade</h4>
          


            <div className="row">
            <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Creche III</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo1}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Creche IV</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Pré II</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo3}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">3º Ano</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo4}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">5º Ano</span>
                        <span className="widget-stats-amount">{data.totalInscricoesTipo5}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
                        
          </>
        )}
    </BaseRemanejamento>
  )
};

export default HomeRemanejamento;

