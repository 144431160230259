import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../../../components/breadcrumb'
import Table from '../../../../../components/table'
import BaseRemanejamento from '../baseRemanejamento'
import urlsRemanejamento from '../urlsRemanejamento'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import api from '../../../../../services'
import { listarEscolas } from '../../../../../services/processos/remanejamento'
import { store } from '../../../../../store'

import { listarInscricoesRemanejamento, gerarRelatorioInscricoesRemanejamento, indeferirInscricao} from '../../../../../services/processos/remanejamento'

import { useQuery, useMutation } from 'react-query';

const RemanejamentoInscricoes = () => {
  const navigate = useNavigate()
  // const [search, setSearch] = useState('')
  const [pesquisarResponsavel, setPesquisarResponsavel] = useState('')
  const [pesquisarAluno, setPesquisaAluno] = useState('')
  const [pesquisaCPF, setpesquisaCPF] = useState('')
  const [data, setData] = useState([])

  const currentUser = store.getState()['user']['user']

  const [escolaridade, setEscolaridade] = useState(0)
  const [bairro, setBairro] = useState(0)
  const [escolaOrigem, setEscolaOrigem] = useState(0)
  const [escolaPretendida1, setEscolaPretendida1] = useState(0)
  const [escolaPretendida2, setEscolaPretendida2] = useState(0)
  const [deficiente, setDeficiente] = useState(0)
  const [status, setStatus] = useState(0)
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const [bairrosData, setBairrosData] = useState([])

  const getBairros = async () => {
    try {
      const res = await api.get(`processoSeletivo/unidade/bairros`)
      setBairrosData(res.data.data)
    } catch (err) {
      console.log({ err })
    }
  }

  const { data: escolas2023,  refetch: refetchEscolas2023} = useQuery(
    'listarescolas2023',
    () => listarEscolas(2023, escolaridade),
    {
      retry: 3,
    }
  )

  const { data: escolas2024, refetch: refetchEscolas2024} = useQuery(
    'listarescolas2024',
    () => listarEscolas(2024, escolaridade),
    {
      retry: 3,
    }
  )

  
  const { mutate } = useMutation(indeferirInscricao, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { isError, isLoading, refetch } = useQuery(
    'inscricoes',
    () => listarInscricoesRemanejamento(escolaridade, bairro, escolaOrigem, escolaPretendida1, escolaPretendida2, deficiente, status, skip,limit),
    {
      retry: 0,
      enabled: false,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )

  const hiddenColluns = { hiddenColumns: ['hash'] };






  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Escolaridade', accessor: 'escolaridadeNome' },
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'Nascimento', accessor: 'dataNascimento',
        Cell: ({ row }) => (
          row.original.dataNascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      // {
      //   Header: 'Escola', accessor: 'unidadeId',
      //   Cell: ({ row }) => (
      //     findMunicipio(row.original.unidadeId)
      //   )
      // },
      { Header: 'Bairro', accessor: 'bairroNome' },
      { Header: 'Unidade Origem', accessor: 'unidadeAtualNome' },
      { Header: 'Unidade Opção 1', accessor: 'unidadeOpcao1Nome' },
      { Header: 'Unidade Opção 2', accessor: 'unidadeOpcao2Nome' },
      { Header: 'Hash', accessor: 'hash' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {currentUser?.moduloGestao && (
              <button onClick={() => navigate(urlsRemanejamento.indeferir + '/' + row.original.id, {
                state: {
                  id: row.original.id,
                }
              })} className='btn btn-sm btn-warning'>
                <i className="material-icons-two-tone">edit_note</i> atualizar
              </button>
              
            )}
            
            <button
              onClick={() =>
                {  window.open(urlsRemanejamento.visualizar + row.original.hash, '_blank')}
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
            
          </>
        )
      }
    ],
    [navigate, mutate]
  )

  useEffect(() => {
    refetch()
    // refetchEscolaridades()
    
  }, [refetch, limit, skip])





  const handleSearch = () => {
    refetch()
  }

  const handleDownloadExcel = async () => {
    await gerarRelatorioInscricoesRemanejamento(escolaridade, bairro, escolaOrigem, escolaPretendida1, escolaPretendida2, deficiente, status, skip, limit)
  }
  
  if (bairrosData.length == 0){
    getBairros()
  }

  return (
    <BaseRemanejamento>
      <Breadcrumb
        title={'Inscrições'}
        itens={['Processo Seletivo', 'Inscrições', 'Lista Geral']}
        hasExcelButton 
        onClickDownload={() => handleDownloadExcel()}
        // actions={{
        //   link: urls.processoSeletivoInscricoesCadastrar,
        //   text: 'Nova Inscição'
        // }}
      />

      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="escolaridadeId" className="form-label">
            Ano de Escolaridade
            </label>
            <select
              className="form-select"
              id="escolaridadeId"
              value={escolaridade}
              onChange={async e => {
                await setEscolaridade(e.target.value)
                refetchEscolas2023()
                refetchEscolas2024()
              }}
            >
              <option></option>
              <option value={0}>TODOS</option>
              <option value={1}>CRECHE III</option>
              <option value={2}>CRECHE IV</option>
              <option value={3}>PRÉ II</option>
              <option value={4}>3º ANO</option>
              <option value={5}>5º ANO</option>
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="bairroId" className="form-label">
              Bairros
            </label>
            <select
              className="form-select"
              id="bairroId"
              value={bairro}
              onChange={async e => {
                await setBairro(e.target.value)
              }}
            >
              
                {/* <option value="">SELECIONE</option> */}
                <>
                  <option value="0">TODOS</option>
                {bairrosData.map(bairro => (
                  <option key={bairro.id} value={bairro.id}>
                    {bairro.descricao}
                  </option>
                    ))}
                </>
            
            </select>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="escolaOrigemId" className="form-label">
              Unidade Em Que Estuda
            </label>
            <select
              className="form-select"
              id="escolaOrigemId"
              value={escolaOrigem}
              onChange={async e => {
                await setEscolaOrigem(e.target.value)
              }}
            >


                <>
                  <option value="0">TODOS</option>
                {escolas2023?.data.map(bairro => (
                  <option key={bairro.id} value={bairro.id}>
                    {bairro.descricao}
                  </option>
                    ))}
                </>
            </select>
          </div>
        </div>

        
        
        

        

        

        

      </div>

      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-6 col-md-6">
          <div className="form-group">
            <label htmlFor="escolaPretendidaId1" className="form-label">
              Escola Pretendida 1
            </label>
            <select
              className="form-select"
              id="escolaPretendidaId1"
              value={escolaPretendida1}
              onChange={async e => {
                await setEscolaPretendida1(e.target.value)
              }}
            >

                {/* <option value="">SELECIONE</option> */}

                <>
                  <option value="0">TODOS</option>
                {escolas2024?.data.map(bairro => (
                  <option key={bairro.id} value={bairro.id}>
                    {bairro.descricao}
                  </option>
                    ))}
                </>
            </select>
          </div>
        </div>

        <div className="col-6 col-md-6">
          <div className="form-group">
            <label htmlFor="escolaPretendidaId2" className="form-label">
            Escola Pretendida 2
            </label>
            <select
              className="form-select"
              id="escolaPretendidaId2"
              value={escolaPretendida2}
              onChange={async e => {
                await setEscolaPretendida2(e.target.value)
              }}
            >

                {/* <option value="">SELECIONE</option> */}

                <>
                  <option value="0">TODOS</option>
                {escolas2024?.data.map(bairro => (
                  <option key={bairro.id} value={bairro.id}>
                    {bairro.descricao}
                  </option>
                    ))}
                </>
            </select>
          </div>
        </div>

        
      

        
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="municipioId" className="form-label">
              Deficiente
            </label>
            <select
              className="form-select"
              id="municipioId"
              // value={unidadeId}
              onChange={async e => {
                await setDeficiente(e.target.value)
              }}
            >

                <>
                <option value="0"></option>
                  <option value={1}>SIM</option>
                  <option value={2}>NÃO</option>
                </>
            </select>
          </div>
        </div>
        
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="status" className="form-label">
              Status?
            </label>
            <select
              className="form-select"
              id="status"
              value={status}
              onChange={async e => {
                await setStatus(e.target.value)
              }}
            >
                <>
                <option value="0"></option>
                <option value="1">EM ANÁLISE</option>
                <option value="2">CANCELADO</option>
                <option value="3">DEFERIDO</option>
                <option value="4">INDEFERIDO</option>
                </>
            </select>
          </div>
        </div>

        

        

        

      </div>


      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >

        {/* <div className="form-group" style={{ width: '100%', maxWidth: 390, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            
            value={pesquisaCPF}
            onChange={e => setpesquisaCPF(e.target.value)}
          />
        </div> */}

        {/* <div className="form-group" style={{ width: '100%', maxWidth: 395, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={e => setPesquisaAluno(e.target.value)}
          />
        </div> */}

        {/* <div className="form-group" style={{ width: '100%', maxWidth: 395 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome Reponsável"
            value={pesquisarResponsavel}
            onChange={e => setPesquisarResponsavel(e.target.value)}
          />
        </div> */}
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
      </div>



      
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={["hash"]}
            />
          </div>
        </div>
      </div>
    </BaseRemanejamento>
  )
}

export default RemanejamentoInscricoes
