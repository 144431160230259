import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import Breadcrumb from "../../../../../../components/breadcrumb";
import Table from "../../../../../../components/table";
import BaseServidores from "../../../public/baseServidores";
import api from "../../../../../../services";
import { downloadAnexo } from "../../../../../../services/processos/remanejamento";


const ServidoresAnexos = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([])

  const appendObject = (id, nome, anexoId) => {
    // Use the spread operator to create a new array with the existing objects and the new object
    setData([...data, { id, nome, anexoId }]);
  };

  const handleAnexoDownload = async (id, nome, anexoId) => {
    let anexo = await downloadAnexo(id, nome, anexoId)
  }

async function fetchData() {
    if (data?.length == 0){
    const response = await api.get(`/processoSeletivo/inscricao/servidor/anexo/${id}`)
    setData(response.data.data)
  }
}



  fetchData()
  
 
  
    const columns2 = useMemo(
      () => [
        { Header: '#', accessor: 'ordem' },
        { Header: 'Categoria', accessor: 'categoria' },
        // { Header: 'Categoria', accessor: 'categoria' },
        {
          Header: 'Baixar', accessor: 'anexoId',
          Cell: ({ row }) => (
            <button className="btn btn-primary btn-sm" onClick={() => handleAnexoDownload(row.original.ordem, row.original.categoria, row.original.anexoId)}>
              <i className="material-icons">download</i> baixar
            </button>
          )
        },
      ],
      []
    )

  return (
    <BaseServidores>
      <Breadcrumb title={'Anexos'} itens={['Processo Seletivo', 'Servidores', 'Anexos']} />

        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                <Table columns={columns2} data={data} />
             

                </>
              </div>
              
            </div>
          </div>
        </div>
    </BaseServidores>
  )
};

export default ServidoresAnexos